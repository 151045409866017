import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import PageNav from '../components/layout/PageNav';


const DownloadAssets = ({ data = {} }) => {

  const { modules, title, description = false, id } = mapPageData(data.page)

  const [modulesToRender, heroModule] = useModules(modules, { getHeroModule: true })

  return (
    <>
      <SEO title={title} description={description} />
      <Layout
        headerClassName=""
        noHero
        staticMenuLayout={true}
        staticMenu
        homeLink={true}
        heroModule={heroModule}
        className="download-assets static-page mx-auto md:mr-auto">
        {/* Content goes here */}


        <div className="xl:min-w-4xl lg:pr-4">
          {modulesToRender.map(({ Module, id }) => <Module key={id} />)}
        </div>

        <PageNav 
        prev={{  text: "Our Product Pillars", linkTo: "/ourproductpillars"}} 
        next={{  text: "FAQs and Contacts", linkTo: "/faqsandcontacts"}}
      />
      

      </Layout>
    </>
  )
}

export default DownloadAssets


export const DownloadAssetsQuery = graphql`
  query DownloadAssetsQuery {
  page: contentfulPage(pageId: {eq: "assets"}) {
      ...PageFragment
    }
  }
`
